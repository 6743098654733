div#ClaimsBox {
    max-width: 960px;
    background-color: #1a1a1a;
    margin: 0 auto;
    border-radius: 8px;
    margin-top: -113px;
    z-index: 1111;
    position: relative;
    padding: 40px 90px;
    margin-bottom: 32px;
}
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 0px;
}
.page-item.active .page-link {
    background-color: #BDB3A2;
    border-color: #BDB3A2;
    color: #000 !important;
    z-index: 3;
}
a.page-link{
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
    color: #fff;
}

.page-item.disabled .page-link {
    color: #fff;
    pointer-events: none;
    background-color: #1a1a1a;
    border-color: #1a1a1a;
    opacity: .6;
}
.page-item:first-child .page-link {
    border-bottom-left-radius: 1000px;
    border-top-left-radius: 1000px;
}
.page-item:last-child .page-link {
    border-bottom-right-radius: 1000px;
    border-top-right-radius: 1000px;
}
.page-link:hover {
    z-index: 2;
    color: #fff;
    background-color: #1a1a1a;
    border-color: #1a1a1a;
}
@media (min-width: 320px) and (max-width: 767px) {
    ul.pagination {
        white-space: nowrap;
        padding-bottom: 10px;
    }
}